import React from "react";
import $ from "jquery";
import { branchAction,orderAction } from "../../../../_actions";
import { Base_API } from "../../../../_constants/matcher";
import SweetAlert from "react-bootstrap-sweetalert";
import "../order.css";
import Select from "react-select";
import ReactExport from 'react-data-export';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

$.DataTable = require("datatables.net");
let modalAlert;
var multiDataSet

let date = new Date()
var dd = date.getDate();
var mm = date.getMonth()+1; 
var yyyy = date.getFullYear();
var hh = date.getHours();
var mn = date.getMinutes();
var ss = date.getSeconds();
var ms = date.getMilliseconds();

if(dd<10) { dd='0'+dd; } 
if(mm<10) { mm='0'+mm; }

let name_excel = dd.toString()+mm.toString()+yyyy.toString()+hh.toString()+mn.toString()+ss.toString()+ms.toString()+'-receive-order-online'

var optionStatus = [
  { value: '4', label: 'รอยืนยันออเดอร์' },
  { value: '5', label: 'คนขับรับออร์เดอร์' },
  { value: '6', label: 'คนขับกำลังไปส่งสินค้า' },
  { value: '7', label: 'รับสินค้าแล้ว' },
  { value: '8', label: 'สินค้าถูกยกเลิก' },
  { value: '10', label: 'สินค้าถูกส่งคืน' },
  { value: '11', label: 'รายการไม่สำเร็จ' },
  { value: '12', label: 'จัดเตรียมสินค้า' },
  { value: '13', label: 'สินค้ากำลังจัดส่ง' },
];
var optionType = [
  { value: 'ems', label: 'ems' },
  { value: 'delivery', label: 'delivery' },
  { value: 'pickup', label: 'Order & Pickup' },
];

export class Order_Online_Recive_List extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.openModalHandler = this.openModalHandler.bind(this);
    this.closeModalHandler = this.closeModalHandler.bind(this);

    this.state = {
      show: false,
      modal: null,
      isModal: false,
      inputBody: [],
      fields: {},
      errors: {},
      branchList: [],
      searchStatus:'',
      searchBranch:''
    };
  }

  openModalHandler() {
    this.setState({
      isModal: true
    });
  }

  closeModalHandler = () => {
    this.setState({
      isModal: false
    });
  };

  componentDidMount() {
    branchAction.listBranchOrderOnline()
    .then(res => {
        this.setState({
            branchList: res.data
        })
    })

    let dataSearch = {
      s_Order: sessionStorage.getItem("searchOnlineOrder") ? sessionStorage.getItem("searchOnlineOrder") : "",
      s_Branch: sessionStorage.getItem("searchOnlineBranch") ? sessionStorage.getItem("searchOnlineBranch") : "",
      s_Date: sessionStorage.getItem("searchOnlineDate") ? sessionStorage.getItem("searchOnlineDate") : "",
      s_DateTo: sessionStorage.getItem("searchOnlineDateTo") ? sessionStorage.getItem("searchOnlineDateTo") : "",
      s_Status: sessionStorage.getItem("searchOnlineStatus") ? sessionStorage.getItem("searchOnlineStatus") : "",
      s_ReceiveChannel: sessionStorage.getItem("searchOnlineReceiveChannel") ? sessionStorage.getItem("searchOnlineReceiveChannel") : ""
    }

    this.showTable(dataSearch);
    this.exportExcel(dataSearch);
  }

  showTable(dataSearch){
    const columns = [
        {
          title: "ลำดับ",
          class: "text-center",
          data: "updated_at"
        },
        {
          title: "วันที่สั่งออเดอร์",
          class: "text-center",
          data: "created_at"
        },
        {
          title: "เลขที่ออเดอร์",
          class: "text-center",
          data: "od_online_number"
        },
        {
          title: "ประเภทการรับสินค้า",
          class: "text-center",
          data: "receive_channel"
        },
        {
          title: "หมายเลข tracking",
          class: "text-center",
          data: "tracking_number"
        },
        {
          title: "สาขา",
          class: "text-center",
          data: "b_name"
        },
        {
          title: "ชื่อลูกค้า",
          class: "text-center",
          data: "mem_firstname",
        },
        {
          title: "เบอร์โทรศัพท์",
          class: "text-center",
          data: "mem_phone",
        },
        {
          title: "เลขบัตรประชาชน",
          class: "text-center",
          data: "mem_idcard",
        },
        {
          title: "ราคารวม",
          class: "text-center",
          data: "sum_price"
        },
        // {
        //   title: "วันที่รับสินค้า",
        //   class: "text-center",
        //   data: "date_recive"
        // },
        {
          title: "ช่องทางการชำระเงิน",
          class: "text-center",
          data: "payment_channel_name"
        },
        {
          title: "สถานะการรับสินค้า",
          class: "text-center",
          data: "receive_name"
        },
        {
          title: "หมายหตุ",
          class: "text-center",
          data: "od_online_comment"
        },
        {
          title: "จัดการ",
          class: "text-center",
          data: "od_online_number"
        },
      ];
    let table = $(this.refs.main).DataTable({
      columnDefs: [
        {
          targets: 0,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(row+1);
          },
          orderable: false
        },
        {
          targets: 2,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`<div class="text-left">${cellData}</div>`);
          },
          orderable: true
        },
        {
          targets: 3,
          createdCell: function(td, cellData, rowData, row, col) {
            var status = '';
            if(rowData.type_order_id == '100') status = `${cellData}`
            else if(rowData.type_order_id == '101') status = `${cellData}`
            else if(rowData.type_order_id == '102') status = `${cellData}`
            else status = `${cellData}`
            $(td).html(`<div class="text-left">${status}</div>`);
          },
          orderable: true
        },
        {
          targets: 5,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`<div class="text-left">${(cellData == null ? "" : cellData )}</div>`);
          }
        },
        {
          targets: 5,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`<div class="text-left">${cellData}</div>`);
          }
        },
        {
          targets: 6,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`<div class="text-left">${cellData} ${rowData.mem_lastname}</div>`);
          }
        },
        {
          targets: 9,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
            .html(`<div class="text-right">${Number(cellData).toLocaleString('th', {minimumFractionDigits: 2})} ฿</div>`);
          }
        },
        {
          targets: 11,
          createdCell: function(td, cellData, rowData, row, col) {
            var status = '';
            if(rowData.status_receive == '4') status = `<span class="badge badge-warning">${cellData}</span>`
            else if(rowData.status_receive == '5') status = `<span class="badge badge-info">${cellData}</span>`
            else if(rowData.status_receive == '6') status = `<span class="badge badge-success">${cellData}</span>`
            else if(rowData.status_receive == '7') status = `<span class="badge badge-success">${cellData}</span>`
            else if(rowData.status_receive == '8') status = `<span class="badge badge-danger">${rowData.status_receive_name}</span>`
            else if(rowData.status_receive == '10') status = `<span class="badge badge-danger">${rowData.status_receive_name}</span>`
            else if(rowData.status_receive == '11') status = `<span class="badge badge-danger">${rowData.status_receive_name}</span>`
            else status = `<span class="badge badge-info">${cellData}</span>`
            $(td).html(status);
          },
          orderable: false
        },
        {
          targets: 13,
          createdCell: function(td, cellData, rowData, row, col) {
            var bt_manage = '';
            var bt_notification = ''
            if(rowData.receive_channel == "delivery" || rowData.tracking_number == null || rowData.tracking_number == ""){
              bt_notification = `<a href="#" class="dropdown-item disabled-btn"><i class="icon-envelope mr-3 mb-1"></i> ส่ง SMS หมายเลข tracking</a>`
            }else{
              let dataSMS = {
                "phone_number":rowData.mem_phone,
                "order_online_number":rowData.od_online_number,
                "tracking_number":rowData.tracking_number
              }
              
              bt_notification =`<button href="#" class="dropdown-item sendSMS" data-value=${JSON.stringify(dataSMS)}><i class="icon-envelope mr-3 mb-1"></i> ส่ง SMS หมายเลข tracking</a>`
            }
            if(rowData.status_receive != '4' && rowData.status_receive != '5' && rowData.status_receive != '12' && rowData.status_receive != '13'){  
              bt_manage =
                `
                <div class="list-icons">
                  <div class="dropdown">
                    <a href="#" class="list-icons-item" data-toggle="dropdown">
                      <i class="icon-menu9"></i>
                    </a>

                    <div class="dropdown-menu dropdown-menu-right">
                      <a href="/backoffice/order-online/receive_order/info/${cellData}" class="dropdown-item"><i class="icon-file-eye2 mr-3 mb-1"></i> ดูรายละเอียด</a>
                      <a href="/backoffice/order-online/receive_order/edit/${cellData}" class="dropdown-item"><i class="icon-cogs mr-3 mb-1"></i> จัดการออเดอร์</a>
                       ${bt_notification}
                    </div>
                  </div>
                </div>
                `
            }else{
              bt_manage =
                `
                <div class="list-icons">
                  <div class="dropdown">
                    <a href="#" class="list-icons-item" data-toggle="dropdown">
                      <i class="icon-menu9"></i>
                    </a>

                    <div class="dropdown-menu dropdown-menu-right">
                      <a href="/backoffice/order-online/receive_order/info/${cellData}" class="dropdown-item"><i class="icon-file-eye2 mr-3 mb-1"></i> ดูรายละเอียด</a>
                      <a href="/backoffice/order-online/receive_order/edit/${cellData}" class="dropdown-item"><i class="icon-cogs mr-3 mb-1"></i> จัดการออเดอร์</a>
                      ${bt_notification}
                    </div>
                  </div>
                </div>
              `
            }

            $(td).html(bt_manage);
          },
          orderable: false
        }
      ],

      ajax: {
        url: `${Base_API.order_online}/order-online-receive?searchOrder=${dataSearch.s_Order}&searchBranch=${dataSearch.s_Branch}&searchDate=${dataSearch.s_Date}&searchDateTo=${dataSearch.s_DateTo}&searchStatus=${dataSearch.s_Status}&searchReceiveChannel=${dataSearch.s_ReceiveChannel}`,
        type: "GET",
        dataType: "JSON",
        data: data => {
          return data;
        }
      },
      order: [[1, "DESC"]],
      columns,
      serverSide: true,
      ordering: true,
      searching: true,
      processing: true,
      bLengthChange: false,
      "language": {
        processing: '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading..n.</span> '
      },
    });

    var state = this;

    $(this.refs.main).on("click", ".sendSMS", function () {
      var obj_data = $(this).data("value");

      orderAction.sendSMS(obj_data).then(e => {
        if (e.isSuccess === false) {
          onModalError("Error", e.message);
        } else {
          onModalSuccess("Success", e.message);
        }
      });
    });
    
    function onModalError(head, body) {
      modalAlert = () => (
        <SweetAlert
          style={{ display: "block" }}
          error
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={onConfirm}
        >
          {body}
        </SweetAlert>
      );

      state.setState({ show: true, modal: modalAlert() });
    }

    function onModalSuccess(head, body) {
      modalAlert = () => (
        <SweetAlert
          style={{ display: "block" }}
          success
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={onConfirm}
        >
          {body}
        </SweetAlert>
      );

      state.setState({ show: true, modal: modalAlert() });
    }

    function onConfirm(ev) {
      state.setState({ modal: null });
    }

  }


  
    handleSubmit(e) {
      var table = $('#dataTable').DataTable();
      table.destroy();
      e.preventDefault()
      if (this.validateForm()) {
        let searchStatus = (this.state.fields["searchStatus"] ? this.state.fields["searchStatus"] : '')
        let searchBranch = (this.state.fields["searchBranch"] ? this.state.fields["searchBranch"] : '')
        let searchOrder = (this.state.fields["searchOrder"] ? this.state.fields["searchOrder"] : '')
        let searchDate = (this.state.fields["searchDate"] ? this.state.fields["searchDate"] : '')
        let searchDateTo = (this.state.fields["searchDateTo"] ? this.state.fields["searchDateTo"] : '')
        let searchReceiveChannel = (this.state.fields["searchReceiveChannel"] ? this.state.fields["searchReceiveChannel"] : '')

        sessionStorage.setItem("searchOnlineStatus", searchStatus);
        sessionStorage.setItem("searchOnlineBranch", searchBranch);
        sessionStorage.setItem("searchOnlineOrder", searchOrder);
        sessionStorage.setItem("searchOnlineDate", searchDate);
        sessionStorage.setItem("searchOnlineDateTo", searchDateTo);
        sessionStorage.setItem("searchOnlineReceiveChannel", searchReceiveChannel);

        var dataSearch = {
            s_Status: searchStatus,
            s_Branch: searchBranch,
            s_Order: searchOrder,
            s_Date: searchDate,
            s_DateTo: searchDateTo,
            s_ReceiveChannel: searchReceiveChannel
        }
        this.showTable(dataSearch);
        this.exportExcel(dataSearch)
      }

    }

    clearSearch = (e) => {
      let { fields } = this.state;
      fields['searchStatus']='';
      fields['searchBranch']='';
      fields['searchOrder']='';
      fields['searchDate']='';
      fields['searchDateTo']='';
      fields['searchReceiveChannel']='';
      
      this.selectRefBranch.select.clearValue();
      this.selectRefStatus.select.clearValue();
      this.selectRefReceiveChannel.select.clearValue();
  
      this.setState({ fields });
    };

    handleSearchStatus = (selectedOption) => {
      if(selectedOption != null){
        let { fields } = this.state;
        fields["searchStatus"] = selectedOption.value;
        this.setState({ fields });
      }
    };

    handleSearchBranch = (selectedOption) => {
      if(selectedOption != null){
        let { fields } = this.state;
        fields["searchBranch"] = selectedOption.value;
        this.setState({ fields });
      }
    };
    handleSearchReceiveChannel = (selectedOption) => {
      console.log(selectedOption);
      if(selectedOption != null){
        let { fields } = this.state;
        fields["searchReceiveChannel"] = selectedOption.value;
        this.setState({ fields });
      }
    };

    handleChangeSearch = (e) => {
        let { fields,errors } = this.state;
        fields[e.target.name] = e.target.value;
        this.state.errors[e.target.name] = null;
        this.setState({ fields,errors });
    };

    exportExcel = (dataSearch) => {
      orderAction.getReceiveOrderOnlineListExcel(dataSearch).then(res => {
        this.setState({
            dataOrder: res.data
        })
      })
    };

    validateForm() {
      let fields = this.state.fields;
      let errors = {};
      let formIsValid = true;
  
      if (fields["searchDate"] && !fields["searchDateTo"]) {
        formIsValid = false;
        errors["searchDateTo"] = "*กรุณากรอกวันที่สร้างออเดอร์";
      }
  
      if (!fields["searchDate"] && fields["searchDateTo"]) {
        formIsValid = false;
        errors["searchDate"] = "*กรุณากรอกวันที่สร้างออเดอร์";
      }
  
      this.setState({
        errors: errors
      });
      return formIsValid;
    }

  render() {
    let optionBranch = this.state.branchList.map(function (branchList) {
      return { value: branchList.b_id, label: branchList.b_name };
    })

    var dataOrder = []
    let data = this.state.dataOrder  
    for(var i in data){
      dataOrder.push(
        [
          {value: parseInt(i)+1},
          {value: data[i].created_at},
          {value: data[i].od_online_number},
          {value: data[i].receive_channel},
          {value: (data[i].b_name ? data[i].b_name : '')},
          {value: data[i].mem_firstname + ' ' + data[i].mem_lastname},
          {value: data[i].mem_phone},
          {value: (data[i].mem_idcard ? data[i].mem_idcard : '')},
          {value: data[i].sum_price},
          {value: data[i].payment_channel_name},
          {value: data[i].receive_name},
          {value: (data[i].od_online_comment ? data[i].od_online_comment : "")}
        ],
      )
    }    

    multiDataSet = [
      {
          columns: [
              {title: "#"},//pixels width 
              {title: "วันที่สั่งออเดอร์", width: {wch: 30}},//char width 
              {title: "เลขที่ออเดอร์", width: {wch: 30}},//char width 
              {title: "ช่องทางการรับสินค้า", width: {wch: 30}},//char width 
              {title: "สาขา", width: {wch: 40}},
              {title: "ชื่อลูกค้า", width: {wch: 40}},
              {title: "เบอร์โทรศัพท์", width: {wch: 40}},
              {title: "เลขที่บัตรประชาชน", width: {wch: 40}},
              {title: "ราคารวม", width: {wch: 20}},
              {title: "ช่องทางการชำระเงิน", width: {wch: 30}},
              {title: "สถานะการรับสินค้า"},
              {title: "หมายเหตุ"},
          ],
          data: dataOrder
          
      }
    ];

    return (
      <div>
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">รายการรับสินค้า (Order Online)</h4>
            <div className="heading-elements">
              <ExcelFile element={
                <button
                  type="button"
                  name="add_banner_btn"
                  data-content=""
                  className="btn btn-add mr-2"
                >
                <i className="icon-download"></i> Export Excel
                </button>
                }
                filename = {name_excel}
              >
                <ExcelSheet dataSet={multiDataSet} name="Receive Order Online" />
              </ExcelFile>
            </div>
          </div>
          <div className="panel-body">
            <form className="form-horizontal" onSubmit={(e) => this.handleSubmit(e)}>
              <div className="panel-body panel-color-gray px-5 py-3">
                <div className="pl-3">
                  <div className="row my-2">
                    <div className="col-md-3">เลขที่ออเดอร์</div>
                    <div className="col-md-3">
                      <input
                        type="text"
                        name="searchOrder"
                        placeholder="เลขที่ออเดอร์"
                        onChange={this.handleChangeSearch}
                        className="form-control"
                        value={this.state.fields['searchOrder']}
                      />
                    </div>
                    <div className="col-md-3">ประเภทการรับสินค้า</div>
                    <div className="col-md-3">
                      <Select
                        name="searchReceiveChannel"
                        placeholder="เลือกประเภทการรับสินค้า"
                        onChange={this.handleSearchReceiveChannel}
                        options={optionType}
                        className="Select-menu-outer"
                        ref={ref => {
                        this.selectRefReceiveChannel = ref;
                        }}
                      />
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-md-3">สาขา</div>
                    <div className="col-md-3">
                      <Select
                        name="searchBranch"
                        placeholder="เลือกสาขา"
                        onChange={this.handleSearchBranch}
                        options={optionBranch}
                        className="Select-menu-outer"
                        ref={ref => {
                        this.selectRefBranch = ref;
                        }}
                      />
                    </div>
                    <div className="col-md-3">สถานะออเดอร์</div>
                    <div className="col-md-3">
                      <Select
                        name="searchStatus"
                        placeholder="เลือกสถานะออเดอร์"
                        onChange={this.handleSearchStatus}
                        options={optionStatus}
                        className="Select-menu-outer"
                        ref={ref => {
                        this.selectRefStatus = ref;
                        }}
                      />
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-md-3">วันที่สร้างออเดอร์</div>
                    <div className="col-md-3">
                      <input
                        type="date"
                        name="searchDate"
                        placeholder=""
                        onChange={this.handleChangeSearch}
                        className="form-control"
                        value={this.state.fields['searchDate']}
                      />
                      <div className="errorMsg">{this.state.errors.searchDate}</div>
                    </div>

                    <div className="col-md-3">ถึง วันที่สร้างออเดอร์</div>
                    <div className="col-md-3">
                      <input
                        type="date"
                        name="searchDateTo"
                        placeholder=""
                        onChange={this.handleChangeSearch}
                        className="form-control"
                        value={this.state.fields['searchDateTo']}
                      />
                      <div className="errorMsg">{this.state.errors.searchDateTo}</div>

                    </div>
                  </div>

                  <div className="form-group mt-3">
                    <input
                        type="submit"
                        className="btn btn-add btn-md btn-banner-create mr-3"
                        value="ค้นหา"
                    />
                    <input
                        type="button"
                        className="btn btn-clear btn-md"
                        onClick={this.clearSearch}
                        value="เคลียร์ข้อมูลค้นหา"
                    />
                  </div>
                </div>
              </div>
            </form>
            <div className="row">
              <div className="col-md-12">
                <div className="table-responsive">
                  <table id="dataTable" className="table table-hover" ref="main" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
