import React from "react";
import $ from "jquery";
import { productAction } from "../../../../_actions";
import { Base_API } from "../../../../_constants/matcher";
import SweetAlert from "react-bootstrap-sweetalert";
import "../product.css";
import ReactExport from 'react-data-export';

$.DataTable = require("datatables.net");
let modalAlert;
var multiDataSet

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

let date = new Date()
var dd = date.getDate();
var mm = date.getMonth()+1; 
var yyyy = date.getFullYear();
var hh = date.getHours();
var mn = date.getMinutes();
var ss = date.getSeconds();
var ms = date.getMilliseconds();

if(dd<10) 
{
  dd='0'+dd;
} 

if(mm<10) 
{
  mm='0'+mm;
}

let name_excel = dd.toString()+mm.toString()+yyyy.toString()+hh.toString()+mn.toString()+ss.toString()+ms.toString()+'-online-product'

export class Product_Online_List extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.openModalHandler = this.openModalHandler.bind(this);
    this.closeModalHandler = this.closeModalHandler.bind(this);

    this.state = {
      member: [],
      memberid: "",
      resp: "",
      respid: [],
      show: false,
      modal: null,
      isModal: false
    };
  }

  openModalHandler() {
    this.setState({
      isModal: true
    });
  }

  closeModalHandler = () => {
    this.setState({
      isModal: false
    });
  };

  componentDidMount() {

    productAction.getProductOnlineList()
    .then(res => {
      this.setState({
          dataProduct: res.data
      })
    })

    const columns = [
      {
        title: "ลำดับ",
        class: "text-center",
        data: "pr_online_id"
      },
      {
        title: "รูปภาพ",
        class: "text-center",
        data: "pr_online_image"
      },
      {
        title: "ไอดีสินค้า",
        class: "text-center",
        data: "pr_online_id"
      },
      {
        title: "รหัสสินค้า",
        class: "text-center",
        data: "pr_online_code"
      },
      {
        title: "ชื่อสินค้า (TH)",
        class: "text-center",
        data: "pr_online_name"
      },
      {
        title: "หมวดหมู่",
        class: "text-center",
        data: "pr_online_category_name"
      },
      {
        title: "ราคาสินค้าตั้งต้น",
        class: "text-center",
        data: "pr_online_price"
      },
      {
        title: "ลำดับที่แสดง",
        class: "text-center",
        data: "pr_online_sort"
      },
      {
        title: "สถานะ Recommend",
        class: "text-center",
        data: "is_recommend"
      },
      {
        title: "สถานะสินค้า",
        class: "text-center",
        data: "is_active"
      },
      {
        title: "วันที่แก้ไข",
        class: "text-center",
        data: "updated_at"
      },
      {
        title: "จัดการ",
        class: "text-center",
        data: "pr_online_id"
      }
    ];
    let table = $(this.refs.main).DataTable({
      columnDefs: [
        {
          targets: 0,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(row+1);
          }
        },
        {
          targets: 1,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`<img class='product_thumbnail' src='${cellData}' alt='${rowData.pr_online_name}' />`);
          },
          orderable: false
        },
        {
          targets: 2,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
            .html(`<div class="text-center">${cellData}</div>`);
          },
          orderable: true
        },
        {
          targets: 3,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
            .html(`<div class="text-center">${cellData}</div>`);
          },
          orderable: true
        },
        {
          targets:4,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
            .html(`<div class="text-center">${cellData}</div>`);
          },
          orderable: true
        },
        {
          targets:5,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
            .html(`<div class="text-left">${cellData}</div>`);
          },
          orderable: true
        },
        {
          targets: 8,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`
                <label class="switch">
                  <input name="is_recommend" class="isRecommend" data-id="${rowData.pr_online_id}" type="checkbox" ${cellData===1 ? "checked" : ""}/>
                  <div class="slider"></div>
                </label>
              `);
          },
          orderable: true
        },
        {
          targets: 9,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`
                <label class="switch">
                  <input name="isStatus" class="activeBtn" data-id="${rowData.pr_online_id}" type="checkbox" ${cellData===1 ? "checked" : ""}/>
                  <div class="slider"></div>
                </label>
              `);
          },
          orderable: true
        },
        {
          targets: 11,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`
              <div class="list-icons">
                <div class="dropdown">
                  <a href="#" class="list-icons-item" data-toggle="dropdown">
                    <i class="icon-menu9"></i>
                  </a>

                  <div class="dropdown-menu dropdown-menu-right">
                    <a href="/backoffice/order-online/product/edit/${cellData}" class="dropdown-item"><i class="icon-pencil3 mr-3 mb-1"></i> แก้ไข</a>
                    <button type='button' class="dropdown-item" name='delete_btn' data-content='${cellData}'><i class="icon-trash mr-3 mb-1"></i>ลบ</button>
                  </div>
                </div>
              </div>
              `);
          },
          orderable: false
        }
      ],
      ajax: {
        url: `${Base_API.product}/product_online`,
        type: "GET",
        dataType: "JSON",
        data: data => {
          return data;
        }
      },
      order: [[11, "DESC"]],
      columns,
      serverSide: true,
      ordering: true,
      searching: true,
      processing: true,
      bLengthChange: false,
      "language": {
        processing: '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading..n.</span> '
      },
    });

    var state = this;

    $(this.refs.main).on("click", ".activeBtn", function () {
      var fid = $(this).data("id");
      var fval = $(this).is(":checked") === true ? 1 : 2;

      var formData = new FormData();
      formData.append("is_active", fval);

      productAction.updateProductOnline(formData, fid).then(e => {
        if (e.isSuccess === false) {
          onModalError("Error", e.message);
        } else {
          onModalSuccess("Success", e.message);
        }
      });
    });

    $(this.refs.main).on("click", ".isRecommend", function () {
      var fid = $(this).data("id");
      var fval = $(this).is(":checked") === true ? 1 : 0;

      var formData = new FormData();
      formData.append("is_recommend", fval);

      productAction.updateProductOnline(formData, fid).then(e => {
        if (e.isSuccess === false) {
          onModalError("Error", e.message);
        } else {
          onModalSuccess("Success", e.message);
        }
      });
    });

    $(this.refs.main).on("click", 'button[name="delete_btn"]', function() {
      var mcid = $(this).data("content");
      modalAlert = () => (
        <SweetAlert
          style={{ display: "block" }}
          info
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title="Delete"
          onConfirm={() => onConfirmDelete(mcid)}
          onCancel={() => onConfirm()}
        >
          คุณต้องการลบข้อมูลนี้หรือไม่ ?
        </SweetAlert>
      );

      state.setState({ show: true, modal: modalAlert() });
    });

    function onConfirmDelete(cid) {
      // ( V1 )
      // productAction.deletePreProduct(cid).then(e => {
      //   if (e.isSuccess === false) {
      //     onModalError("Error", e.message);
      //   } else {
      //     onModalSuccess("Success", e.message);
      //     table.ajax.reload();
      //   }
      // });
      // ( V2 )
      productAction.deletePreProductV2(cid).then(e => {
        if (e.isSuccess === false) {
          onModalError("Error", e.message);
        } else {
          onModalSuccess("Success", e.message);
          table.ajax.reload();
        }
      });
    }

    function onModalSuccess(head, body) {
      modalAlert = () => (
        <SweetAlert
          style={{ display: "block" }}
          success
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={onConfirm}
        >
          {body}
        </SweetAlert>
      );

      state.setState({ show: true, modal: modalAlert() });
    }

    function onModalError(head, body) {
      modalAlert = () => (
        <SweetAlert
          style={{ display: "block" }}
          error
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={onConfirm}
        >
          {body}
        </SweetAlert>
      );

      state.setState({ show: true, modal: modalAlert() });
    }

    function onConfirm(ev) {
      state.setState({ modal: null });
    }
  }

  render() {

    var dataExcel = []
    let data = this.state.dataProduct 

    for(var i in data){
      dataExcel.push(
        [
          {value: i},
          {value: data[i].pr_online_image},
          {value: data[i].pr_online_code},
          {value: data[i].pr_online_name},
          {value: data[i].pr_online_category_name},
          {value: data[i].pr_online_price},
          {value: data[i].pr_online_sort},
          {value: (data[i].is_active == 1 ? 'active' : 'inactive')}
        ],
      )
    }    
    
    multiDataSet = [
      {
          columns: [
              {title: "#"},//pixels width 
              {title: "URL รูปภาพ", width: {wch: 40}},//char width 
              {title: "รหัสสินค้า", width: {wch: 20}},
              {title: "ชื่อสินค้า (TH)", width: {wch: 40}},
              {title: "หมวดหมู่", width: {wch: 20}},
              {title: "ราคาสินค้าตั้งต้น", width: {wch: 10}},
              {title: "ลำดับที่แสดง", width: {wch: 15}},
              {title: "สถานะ"},
          ],
          data: dataExcel
          
      }
    ];

    return (
      <div>
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">จัดการสินค้าออนไลน์</h4>
            <div className="heading-elements">
              <a href="/backoffice/order-online/product/create">
                <button
                  type="button"
                  name="add_banner_btn"
                  data-content=""
                  className="btn btn-add mr-2"
                >
                  เพิ่มสินค้า
                </button>
              </a>

              <a href="/backoffice/order-online/product/importexcel">
                <button
                  type="button"
                  name="add_banner_btn"
                  data-content=""
                  className="btn btn-add mr-2"
                >
                  Import Excel
                </button>
              </a>

              <ExcelFile 
                element={
                  <button
                  type="button"
                  name="add_banner_btn"
                  data-content=""
                  className="btn btn-add mr-2"
                  >
                  <i className="icon-download"></i> Export Excel
                  </button>
                }
                filename = {name_excel}
              >
                <ExcelSheet dataSet={multiDataSet} name="Online-Product" />
              </ExcelFile>
            </div>
          </div>
          <div className="table-responsive">
            <table id="dataTable" className="table table-hover" ref="main" />
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
