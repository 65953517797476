import React from "react";
import $ from "jquery";
import { rewardAction,pointAction } from "../../../../_actions";
import { Base_API } from "../../../../_constants/matcher";
import SweetAlert from "react-bootstrap-sweetalert";
import "../../point.css";

$.DataTable = require("datatables.net");
let modalAlert;

export class Point_Burn_List extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.openModalHandler = this.openModalHandler.bind(this);
    this.closeModalHandler = this.closeModalHandler.bind(this);

    this.state = {
      member: [],
      memberid: "",
      resp: "",
      respid: [],
      show: false,
      modal: null,
      isModal: false
    };
  }

  openModalHandler() {
    this.setState({
      isModal: true
    });
  }

  closeModalHandler = () => {
    this.setState({
      isModal: false
    });
  };

  componentDidMount() {
    const columns = [
      {
        title: "วันที่ทำรายการ",
        class: "text-center",
        data: "created_at"
      },
      {
        title: "รหัสสมาชิก",
        data: "mem_number"
      },
      {
        title: "ชื่อสมาชิก",
        data: "mem_firstname"
      },
      {
        title: "เบอร์โทร",
        data: "mem_phone"
      },
      {
        title: "รหัสรีวอร์ด",
        data: "rewards_code"
      },
      {
        title: "ชื่อรีวอร์ด",
        data: "rewards_name_th"
      },
      {
        title: "คะแนน (P)",
        data: "p_point",
        className: "text-center",
      },
      {
        title: "จัดการ",
        data: "p_id",
        className: "text-center",
      }
    ];
    let table = $(this.refs.main).DataTable({
      columnDefs: [
        {
          targets: 2,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`${rowData.mem_firstname} ${rowData.mem_lastname}`);
          },
          orderable: false
        },
        {
          targets: 6,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`<div class="text-danger"> - ${cellData}</div>`);
          },
          orderable: false
        },
        {
          targets: 7,
          createdCell: function(td, cellData, rowData, row, col) {
            var btn_manage = ""
              btn_manage = 
              `<div class="list-icons">
                <div class="dropdown">
                  <a href="#" class="list-icons-item" data-toggle="dropdown">
                    <i class="icon-menu9"></i>
                  </a>

                  <div class="dropdown-menu dropdown-menu-right">
                    <button type='button' class="dropdown-item" name='delete_btn' data-content='${cellData}'><i class="icon-trash mr-3 mb-1"></i> ลบ </button>
                  </div>
                </div>
              </div>`
            $(td).html(btn_manage);
          },
          orderable: false
        }
      ],
      ajax: {
        url: `${Base_API.point}/api/point/v1/listBurn`,
        type: "GET",
        dataType: "JSON",
        data: data => {
          return data;
        }
      },
      order: [[0, "desc"]],
      columns,
      serverSide: true,
      ordering: true,
      searching: true,
      processing: true,
      bLengthChange: false,
      "language": {
        processing: '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading..n.</span> '
      },
    });

    var state = this;

    $(this.refs.main).on("click", 'button[name="delete_btn"]', function() {
      var Id = $(this).data("content");
      modalAlert = () => (
        <SweetAlert
          style={{ display: "block" }}
          info
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title="Delete"
          onConfirm={() => onConfirmDelete(Id)}
          onCancel={() => onConfirm()}
        >
          คุณต้องการลบข้อมูลนี้หรือไม่ ?
        </SweetAlert>
      );

      state.setState({ show: true, modal: modalAlert() });
    });

    function onConfirmDelete(Id) {
      pointAction.deleteManagePoint(Id).then(e => {
        if (e.isSuccess === false) {
          onModalError("Error", e.message);
        } else {
          onModalSuccess("Success", e.message);
          table.ajax.reload();
        }
      });
    }

    function onModalSuccess(head, body) {
      modalAlert = () => (
        <SweetAlert
          style={{ display: "block" }}
          success
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={onConfirm}
        >
          {body}
        </SweetAlert>
      );

      state.setState({ show: true, modal: modalAlert() });
    }

    function onModalError(head, body) {
      modalAlert = () => (
        <SweetAlert
          style={{ display: "block" }}
          error
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={onConfirm}
        >
          {body}
        </SweetAlert>
      );

      state.setState({ show: true, modal: modalAlert() });
    }

    function onConfirm(ev) {
      state.setState({ modal: null });
    }
  }

  render() {
    return (
      <div>
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">ประวัติการแลกแต้ม</h4>
            <div className="heading-elements">
              {/* <a href="/reward/earn/craete">
                <button
                  type="button"
                  name="add_banner_btn"
                  data-content=""
                  className="btn btn-success"
                >
                  <i class="fa fa-plus" /> เพิ่มรายการ
                </button>
              </a> */}
            </div>
          </div>
          <div className="table-responsive">
            <table id="dataTable" className="table table-hover" ref="main" />
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
