import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { adminAction } from "../../_actions";

import "./sidebar.css";

export class Sidebar extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleActive = this.handleActive.bind(this);

    this.state = {
      active: 'Homepage',
      // active: '',
      subactive: '',
      level_admin: 0
    };

  }

  componentDidMount() {
    var level = localStorage.getItem('level')
    this.getLevelAdmin(level);
  }

  getLevelAdmin(level) {
    adminAction.getLevelById(level).then(e => {
      if (e.isSuccess === true) {
        this.setState({
          level_admin: e.data.level_id,
          menu_general : e.data.menu_general,
          menu_promotion : e.data.menu_promotion,

          menu_branch : e.data.menu_branch,
          menu_branch_queue : e.data.menu_branch_queue,
          menu_branch_detail : e.data.menu_branch_detail,

          menu_preorder : e.data.menu_preorder,
          menu_preorder_sub_order_eatin : e.data.menu_preorder_sub_order_eatin,
          menu_preorder_sub_order_takehome : e.data.menu_preorder_sub_order_takehome,
          menu_preorder_sub_product_preorder : e.data.menu_preorder_sub_product_preorder,
          menu_preorder_sub_product_by_branch : e.data.menu_preorder_sub_product_by_branch,
          menu_preorder_sub_product_preorder_category : e.data.menu_preorder_sub_product_preorder_category,

          menu_order_online : e.data.menu_order_online,
          menu_order_online_sub_order : e.data.menu_order_online_sub_order,
          menu_order_online_sub_receive_order : e.data.menu_order_online_sub_receive_order,
          menu_order_online_sub_product_by_branch : e.data.menu_order_online_sub_product_by_branch,
          menu_order_online_sub_product_online : e.data.menu_order_online_sub_product_online,
          menu_order_online_sub_product_online_category : e.data.menu_order_online_sub_product_online_category,

          menu_product_online : e.data.menu_product_online,
          menu_product_online_sub_product_online : e.data.menu_product_online_sub_product_online,
          menu_product_online_sub_product_addon : e.data.menu_product_online_sub_product_addon,
          menu_product_online_sub_product_by_branch : e.data.menu_product_online_sub_product_by_branch,
          menu_product_online_sub_product_online_category : e.data.menu_product_online_sub_product_online_category,

          menu_manage_promotion : e.data.menu_manage_promotion,
          menu_product_online_promotion : e.data.menu_product_online_promotion,
          menu_manage_promotion_sub_payment : e.data.menu_manage_promotion_sub_payment,
          
          menu_rewards : e.data.menu_rewards,
          menu_rewards_category: e.data.menu_rewards_category,
          menu_rewards_items: e.data.menu_rewards_items,
          menu_rewards_staff: e.data.menu_rewards_staff,
          menu_rewards_history: e.data.menu_rewards_history,

          menu_news : e.data.menu_news,

          menu_member : e.data.menu_member,
          menu_member_list: e.data.menu_member_list,
          menu_staffs: e.data.menu_staffs,

          menu_point_log : e.data.menu_point_log,
          menu_point_earn : e.data.menu_point_earn,
          menu_point_burn : e.data.menu_point_burn,

          menu_ewallet : e.data.menu_ewallet,
          menu_ewallet_account : e.data.menu_ewallet_account,
          menu_ewallet_transaction : e.data.menu_ewallet_transaction,
          menu_ewallet_report : e.data.menu_ewallet_report,

          menu_activity : e.data.menu_activity,

          menu_admin : e.data.menu_admin,
          menu_admin_list: e.data.menu_admin_list,
          menu_permission: e.data.menu_permission,

          menu_problem : e.data.menu_problem,
          menu_problem_title : e.data.menu_problem_title,
          menu_problem_list : e.data.menu_problem_list
        })
        
      }
    });
  }
  

  handleActive(menuItem,e) {
    this.setState({ active: '' });
    this.setState({ subactive: '' });
    this.setState({ active: menuItem });
  }

  handleActiveSubmenu(menuItem) {
    this.setState({ subactive: '' });
    this.setState({ subactive: menuItem });
  }


  render() {

    const activeStyle = 'active'
    const displaySubItem = { display: 'block' };
    const hideSubItem = { display: 'none' };
    let {level_admin} = this.state;

    const urlPath = window.location.hostname
    var urlAU = ""
    if (urlPath === "https://podsable.com" || urlPath === "podsable.com") {
      // urlAU = "/afteryou"  
    }

    return (
      
      <div className="sidebar sidebar-main">
        <div className="sidebar-content">
          <div className="sidebar-user">
            <div className="category-content" style={{ padding: "10px" }}>
              <div className="media">
                <div className="media-body">
                  <span className="media-heading text-bold" style={{ color: "#9f9f9f" }}>จัดการเมนู</span>
                </div>
              </div>
            </div>
          </div>
          <div className="sidebar-category sidebar-category-visible">
            <div className="category-content no-padding">
              <ul className="navigation navigation-main navigation-accordion">

                <li className={this.state.active === 'Homepage' ? activeStyle: ''}>
                  <NavLink to={urlAU + "/backoffice"} className={this.state.active === 'Homepage' ? 'navbar-item': 'navbar-item'} onClick={this.handleActive.bind(this, 'Homepage')}>
                    <img src={process.env.PUBLIC_URL+'/images/icon_afteryou/home.png'} className="img-icon" />
                      <span>แดชบอร์ด</span>
                  </NavLink>
                </li>

                <li className={(this.state.menu_general === 1 ? (this.state.active === 'General' ? activeStyle: '' ) : 'menu-none')}>
									<a className="has-ul" onClick={this.handleActive.bind(this, 'General')}>
                    <img src={process.env.PUBLIC_URL+'/images/icon_afteryou/general.png'} className="img-icon" />
                    <span>จัดการข้อมูลทั่วไป</span>
                  </a>
									<ul className="hidden-ul" style={this.state.subactive === 'G-Banner' || this.state.subactive === 'G-Contact' ? displaySubItem : hideSubItem}>
                    <li className={this.state.subactive === 'G-Banner' ? activeStyle: ''}>
                      <NavLink to={urlAU + "/backoffice/banner"} className={this.state.subactive === 'G-Banner' ? 'navbar-item': 'navbar-item'} onClick={this.handleActiveSubmenu.bind(this, 'G-Banner')}>
                        <span>รายการแบนเนอร์ทั้งหมด</span>
                      </NavLink>
                    </li>
                    <li className={this.state.subactive === 'G-Contact' ? activeStyle: ''}>
                      <NavLink to={urlAU + "/backoffice/contact"} className={this.state.subactive === 'G-Contact' ? 'navbar-item': 'navbar-item'} onClick={this.handleActiveSubmenu.bind(this, 'G-Contact')}>
                        <span>รายการข้อมูลติดต่อเรา</span>
                      </NavLink>
                    </li>
									</ul>
								</li>

                <li className={(this.state.menu_promotion === 1 ? (this.state.active === 'Promotion' ? activeStyle: '' ) : 'menu-none')}>
                  <NavLink to={urlAU + "/backoffice/news/promotion"} className={this.state.active === 'Promotion' ? 'navbar-item': 'navbar-item'} onClick={this.handleActive.bind(this, 'Promotion')}>
                    <img src={process.env.PUBLIC_URL+'/images/icon_afteryou/promotion.png'} className="img-icon" />
                    <span>จัดการข่าวโปรโมชั่น</span>
                  </NavLink>
                </li>

                <li className={(this.state.menu_promotion === 1 ? (this.state.active === 'PromotionBanner' ? activeStyle: '' ) : 'menu-none')}>
                  <NavLink to={urlAU + "/backoffice/news/promotion-banner/create"} className={this.state.active === 'Promotion' ? 'navbar-item': 'navbar-item'} onClick={this.handleActive.bind(this, 'PromotionBanner')}>
                    <img src={process.env.PUBLIC_URL+'/images/icon_afteryou/promotion.png'} className="img-icon" />
                    <span>จัดการข่าวโปรโมชั่นแบนเนอร์</span>
                  </NavLink>
                </li>

                {/* <li className={(this.state.menu_promotion === 1 ? (this.state.active === 'BannerPromotion' ? activeStyle: '' ) : 'menu-none')}>
                  <NavLink to={urlAU + "/backoffice/banner_promotion/list"} className={this.state.active === 'BannerPromotion' ? 'navbar-item': 'navbar-item'} onClick={this.handleActive.bind(this, 'BannerPromotion')}>
                    <img src={process.env.PUBLIC_URL+'/images/icon_afteryou/promotion.png'} className="img-icon" />
                    <span>จัดการแบนเนอร์โปรโมชัน</span>
                  </NavLink>
                </li> */}

                <li className={(this.state.menu_branch === 1 ? (this.state.active === 'Branch' ? activeStyle: '' ) : 'menu-none')}>
									<a className="has-ul" onClick={this.handleActive.bind(this, 'Branch')}>
                    <img src={process.env.PUBLIC_URL+'/images/icon_afteryou/branch.png'} className="img-icon" />
                    <span>รายการสาขาทั้งหมด</span>
                  </a>
									<ul className="hidden-ul" style={this.state.subactive === 'SubBranch' || this.state.subactive === 'PO-Queue' ? displaySubItem : hideSubItem}>
                  <li className={(this.state.menu_branch_queue === 1 ? (this.state.subactive === 'PO-Queue' ? activeStyle: '') : 'menu-none')}>
                      <NavLink to={urlAU + "/backoffice/preorder/queue"} className={this.state.subactive === 'PO-Queue' ? 'navbar-item': 'navbar-item'} onClick={this.handleActiveSubmenu.bind(this, 'PO-Queue')}>
                      <span>รายการคิว (Queues)</span>
                      </NavLink>
                  </li>
                  <li className={(this.state.menu_branch_detail === 1 ? (this.state.subactive === 'SubBranch' ? activeStyle: '') : 'menu-none')}>
                      <NavLink to={urlAU + "/backoffice/preorder/branch"} className={this.state.subactive === 'SubBranch' ? 'navbar-item': 'navbar-item'} onClick={this.handleActiveSubmenu.bind(this, 'SubBranch')}>
                        <span>ข้อมูลสาขา</span>
                      </NavLink>
                    </li>
									</ul>
								</li>

                <li className={(this.state.menu_preorder === 1 ? (this.state.active === 'Preorder' ? activeStyle: '' ) : 'menu-none')}>
                  <a className="has-ul" onClick={this.handleActive.bind(this, 'Preorder')}>
                    <img src={process.env.PUBLIC_URL+'/images/icon_afteryou/eatin.png'} className="img-icon" />
                    <span>จัดการรายการสั่งซื้อหน้าร้าน</span>
                  </a>
                  <ul className="hidden-ul" style={this.state.subactive === 'PO-Order'|| this.state.subactive === 'PO-Takehome' || this.state.subactive === 'PO-Receive' || this.state.subactive === 'PO-Product' || this.state.subactive === 'PO-Product-Category' || this.state.subactive === 'PO-Product-Branch' || this.state.subactive === 'PO-Branch' ? displaySubItem : hideSubItem}>
                    <li className={(this.state.menu_preorder_sub_order_eatin === 1 ? (this.state.subactive === 'PO-Order' ? activeStyle: '') : 'menu-none')}>
                      <NavLink to={urlAU + "/backoffice/preorder/order"} className={this.state.subactive === 'PO-Order' ? 'navbar-item': 'navbar-item'} onClick={this.handleActiveSubmenu.bind(this, 'PO-Order')}>
                        <span>รายการสั่งซื้อหน้าร้าน (Eatin)</span>
                      </NavLink>
                    </li>
                    {/* <li className={(this.state.menu_preorder_sub_order_takehome === 1 ? (this.state.subactive === 'PO-Takehome' ? activeStyle: '') : 'menu-none')}>
                      <NavLink to={urlAU + "/backoffice/preorder/delivery"} className={this.state.subactive === 'PO-Takehome' ? 'navbar-item': 'navbar-item'} onClick={this.handleActiveSubmenu.bind(this, 'PO-Takehome')}>
                        <span>รายการสั่งซื้อหน้าร้าน (Takehome)</span>
                      </NavLink>
                    </li> */}
                    {/* <li className={(this.state.menu_preorder_sub_product_preorder === 1 ? (this.state.subactive === 'PO-Product' ? activeStyle: '') : 'menu-none')}>
                      <NavLink to={urlAU + "/backoffice/preorder/product"} className={this.state.subactive === 'PO-Product' ? 'navbar-item': 'navbar-item'} onClick={this.handleActiveSubmenu.bind(this, 'PO-Product')}>
                        <span>รายการสินค้าพรีออเดอร์</span>
                      </NavLink>
                    </li> */}
                    {/* <li className={(this.state.menu_preorder_sub_product_by_branch === 1 ? (this.state.subactive === 'PO-Product-Branch' ? activeStyle: '') : 'menu-none')}>
                      <NavLink to={urlAU + "/backoffice/preorder/product/branch"} className={this.state.subactive === 'PO-Product-Branch' ? 'navbar-item': 'navbar-item'} onClick={this.handleActiveSubmenu.bind(this, 'PO-Product-Branch')}>
                        <span>รายการสินค้าพรีออเดอร์แต่ละสาขา</span>
                      </NavLink>
                    </li> */}
                    {/* <li className={(this.state.menu_preorder_sub_product_preorder_category === 1 ? (this.state.subactive === 'PO-Product-Category' ? activeStyle: '') : 'menu-none')}>
                      <NavLink to={urlAU + "/backoffice/preorder/product/category"} className={this.state.subactive === 'PO-Product-Category' ? 'navbar-item': 'navbar-item'} onClick={this.handleActiveSubmenu.bind(this, 'PO-Product-Category')}>
                        <span>รายการหมวดหมู่สินค้าพรีออเดอร์</span>
                      </NavLink>
                    </li> */}
                  </ul>
                </li>

                <li className={(this.state.menu_order_online === 1 ? (this.state.active === 'Order_Online' ? activeStyle: '' ) : 'menu-none')}>
                  <a className="has-ul" onClick={this.handleActive.bind(this, 'Order_Online')}>
                    <img src={process.env.PUBLIC_URL+'/images/icon_afteryou/preorder.png'} className="img-icon" />
                    <span>จัดการรายการสั่งซื้อออนไลน์</span>
                  </a>
                  <ul className="hidden-ul" style={this.state.subactive === 'O-Order'|| this.state.subactive === 'O-Receive' || this.state.subactive === 'O-Branch' || this.state.subactive === 'O-Gift' ? displaySubItem : hideSubItem}>
                    <li className={(this.state.menu_order_online_sub_order === 1 ? (this.state.subactive === 'O-Order' ? activeStyle: '') : 'menu-none')}>
                      <NavLink to={urlAU + "/backoffice/order-online/order"} className={this.state.subactive === 'O-Order' ? 'navbar-item': 'navbar-item'} onClick={this.handleActiveSubmenu.bind(this, 'O-Order')}>
                        <span>รายการสั่งซื้อออนไลน์</span>
                      </NavLink>
                    </li>
                    <li className={(this.state.menu_order_online_sub_receive_order === 1 ? (this.state.subactive === 'O-Receive' ? activeStyle: '') : 'menu-none')}>
                      <NavLink to={urlAU + "/backoffice/order-online/receive_order"} className={this.state.subactive === 'O-Receive' ? 'navbar-item': 'navbar-item'} onClick={this.handleActiveSubmenu.bind(this, 'O-Receive')}>
                        <span>รายการรับสินค้าสั่งซื้อออนไลน์</span>
                      </NavLink>
                    </li>
                  </ul>
                </li>

                <li className={(this.state.menu_product_online === 1 ? (this.state.active === 'Product_Online' ? activeStyle: '' ) : 'menu-none')}>
                  <a className="has-ul" onClick={this.handleActive.bind(this, 'Product_Online')}>
                    <img src={process.env.PUBLIC_URL+'/images/icon_afteryou/preorder.png'} className="img-icon" />
                    <span>จัดการสินค้าออนไลน์</span>
                  </a>
                  <ul className="hidden-ul" style={this.state.subactive === 'O-Product' || this.state.subactive === 'O-Product-Branch' || this.state.subactive === 'O-Product-Category' || this.state.subactive === 'O-Product-AddOn' ? displaySubItem : hideSubItem}>
                    <li className={(this.state.menu_product_online_sub_product_online === 1 ? (this.state.subactive === 'O-Product' ? activeStyle: '' ) : 'menu-none')}>
                      <NavLink to={urlAU + "/backoffice/order-online/product"} className={this.state.subactive === 'O-Product' ? 'navbar-item': 'navbar-item'} onClick={this.handleActiveSubmenu.bind(this, 'O-Product')}>
                        <span>รายการสินค้าออนไลน์</span>
                      </NavLink>
                    </li>
                    <li className={(this.state.menu_product_online_sub_product_by_branch === 1 ? (this.state.subactive === 'O-Product-Branch' ? activeStyle: '' ) : 'menu-none')}>
                      <NavLink to={urlAU + "/backoffice/order-online/product/branch"} className={this.state.subactive === 'O-Product-Branch' ? 'navbar-item': 'navbar-item'} onClick={this.handleActiveSubmenu.bind(this, 'O-Product-Branch')}>
                        <span>รายการสินค้าออนไลน์แต่ละสาขา</span>
                      </NavLink>
                    </li>
                    <li className={(this.state.menu_product_online_sub_product_online_category === 1 ? (this.state.subactive === 'O-Product-Category' ? activeStyle: '') : 'menu-none')}>
                      <NavLink to={urlAU + "/backoffice/order-online/product/category"} className={this.state.subactive === 'O-Product-Category' ? 'navbar-item': 'navbar-item'} onClick={this.handleActiveSubmenu.bind(this, 'O-Product-Category')}>
                        <span>รายการหมวดหมู่สินค้าออนไลน์</span>
                      </NavLink>
                    </li>
                    <li className={(this.state.menu_product_online_sub_product_addon === 1 ? (this.state.subactive === 'O-Product-AddOn' ? activeStyle: '' ) : 'menu-none')}>
                      <NavLink to={urlAU + "/backoffice/order-online/product/add-on"} className={this.state.subactive === 'O-Product-AddOn' ? 'navbar-item': 'navbar-item'} onClick={this.handleActiveSubmenu.bind(this, 'O-Product-AddOn')}>
                        <span>รายการคุณลักษณะสินค้าออนไลน์</span>
                      </NavLink>
                    </li>
                  </ul>
                </li>

                <li className={(this.state.menu_manage_promotion === 1 ? (this.state.active === 'manage_promotion' ? activeStyle: '' ) : 'menu-none')}>
                  <a className="has-ul" onClick={this.handleActive.bind(this, 'manage_promotion')}>
                    <img src={process.env.PUBLIC_URL+'/images/icon_afteryou/preorder.png'} className="img-icon" />
                    <span>จัดการรายการโปรโมชั่น</span>
                  </a>
                  <ul className="hidden-ul" style={this.state.subactive === 'O-Product-Promotion' || this.state.subactive === 'Promotion-Sub-Payment' ? displaySubItem : hideSubItem}>
                    <li className={(this.state.menu_product_online_promotion === 1 ? (this.state.subactive === 'O-Product-Promotion' ? activeStyle: '' ) : 'menu-none')}>
                      <NavLink to={urlAU + "/backoffice/product/promotion"} className={this.state.subactive === 'O-Product-Promotion' ? 'navbar-item': 'navbar-item'} onClick={this.handleActiveSubmenu.bind(this, 'O-Product-Promotion')}>
                        <span>โปรโมชั่นสินค้า</span>
                      </NavLink>
                    </li> 
                    <li className={(this.state.menu_manage_promotion_sub_payment === 1 ? (this.state.subactive === 'Promotion-Sub-Payment' ? activeStyle: '' ) : 'menu-none')}>
                      <NavLink to={urlAU + "/backoffice/promotion/payment"} className={this.state.subactive === 'Promotion-Sub-Payment' ? 'navbar-item': 'navbar-item'} onClick={this.handleActiveSubmenu.bind(this, 'Promotion-Sub-Payment')}>
                        <span>โปรโมชั่นการชำระเงิน</span>
                      </NavLink>
                    </li> 
                  </ul>
                </li>

                <li className={(this.state.menu_rewards === 1 ? (this.state.active === 'Reward' ? activeStyle: '' ) : 'menu-none')}>
									<a className="has-ul" onClick={this.handleActive.bind(this, 'Reward')}>
                    <img src={process.env.PUBLIC_URL+'/images/icon_afteryou/rewards.png'} className="img-icon" />
                    <span>รายการรีวอร์ด</span>
                  </a>
									<ul className="hidden-ul" style={this.state.subactive === 'R-Cate' || this.state.subactive === 'R-List' || this.state.subactive === 'R-History' || this.state.subactive === 'R-Staff-List' ? displaySubItem : hideSubItem}>
                    <li className={(this.state.menu_rewards_category === 1 ? (this.state.subactive === 'R-Cate' ? activeStyle: '') : 'menu-none')}>
                      <NavLink to={urlAU + "/backoffice/reward/category"} className={this.state.subactive === 'R-Cate' ? 'navbar-item': 'navbar-item'} onClick={this.handleActiveSubmenu.bind(this, 'R-Cate')}>
                        <span>หมวดหมู่รีวอร์ด</span>
                      </NavLink>
                    </li>
                    <li className={(this.state.menu_rewards_items === 1 ? (this.state.subactive === 'R-List' ? activeStyle: '') : 'menu-none')}>
                      <NavLink to={urlAU + "/backoffice/reward/list"} className={this.state.subactive === 'R-List' ? 'navbar-item': 'navbar-item'} onClick={this.handleActiveSubmenu.bind(this, 'R-List')}>
                        <span>รายการรีวอร์ดทั้งหมด</span>
                      </NavLink>
                    </li>
                    <li className={(this.state.menu_rewards_staff === 1 ? (this.state.subactive === 'R-Staff-List' ? activeStyle: '') : 'menu-none')}>
                      <NavLink to={urlAU + "/backoffice/reward/staff/list"} className={this.state.subactive === 'R-Staff-List' ? 'navbar-item': 'navbar-item'} onClick={this.handleActiveSubmenu.bind(this, 'R-Staff-List')}>
                        <span>จัดการรายการรีวอร์ดสำหรับพนักงาน</span>
                      </NavLink>
                    </li>
                    <li className={(this.state.menu_rewards_history === 1 ? (this.state.subactive === 'R-History' ? activeStyle: '') : 'menu-none')}>
                      <NavLink to={urlAU + "/backoffice/reward/burn/list"} className={this.state.subactive === 'R-History' ? 'navbar-item': 'navbar-item'} onClick={this.handleActiveSubmenu.bind(this, 'R-History')}>
                        <span>ประวัติการแลกรีวอร์ด</span>
                      </NavLink>
                    </li>
									</ul>
								</li>

                {!!level_admin && level_admin === 1 ? //จำกัดสิทธิ์
                  <li className={this.state.active === 'Edit Payment Channel' ? 'active': ''}>
                    <NavLink to={urlAU + "/backoffice/paymentchannel_status/edit"} className={this.state.active === 'Edit Payment Channel' ? 'navbar-item': 'navbar-item'} onClick={this.handleActive.bind(this, 'Edit Payment Channel')}>
                      <img src={process.env.PUBLIC_URL+'/images/icon_afteryou/news.png'} className="img-icon" />
                      <span>ช่องทางการชำระเงิน</span>
                    </NavLink>
                  </li>
                  : null
                }

                <li className={(this.state.menu_news === 1 ? (this.state.active === 'News Feed' ? activeStyle: '' ) : 'menu-none')}>
                  <NavLink to={urlAU + "/backoffice/news/news"} className={this.state.active === 'News Feed' ? 'navbar-item': 'navbar-item'} onClick={this.handleActive.bind(this, 'News Feed')}>
                    <img src={process.env.PUBLIC_URL+'/images/icon_afteryou/news.png'} className="img-icon" />
                    <span>แจ้งเตือนข่าวสารทั่วไป</span>
                  </NavLink>
                </li>
                
                <li className={(this.state.menu_member === 1 ? (this.state.active === 'Member' ? activeStyle: '' ) : 'menu-none')}>
									<a className="has-ul" onClick={this.handleActive.bind(this, 'Member')}>
                    <img src={process.env.PUBLIC_URL+'/images/icon_afteryou/member.png'} className="img-icon" />
                    <span>สมาชิก</span>
                  </a>
									<ul className="hidden-ul" style={this.state.subactive === 'SubMember' || this.state.subactive === 'SubStaff' ? displaySubItem : hideSubItem}>
                    <li className={(this.state.menu_member_list === 1 ? (this.state.subactive === 'SubMember' ? activeStyle: '') : 'menu-none')}>
                      <NavLink to={urlAU + "/backoffice/member"} className={this.state.subactive === 'SubMember' ? 'navbar-item': 'navbar-item'} onClick={this.handleActiveSubmenu.bind(this, 'SubMember')}>
                        <span>รายชื่อข้อมูลสมาชิก</span>
                      </NavLink>
                    </li>
                    <li className={(this.state.menu_staffs === 1 ? (this.state.subactive === 'SubStaff' ? activeStyle: '') : 'menu-none')}>
                      <NavLink to={urlAU + "/backoffice/staff"} className={this.state.subactive === 'SubStaff' ? 'navbar-item': 'navbar-item'} onClick={this.handleActiveSubmenu.bind(this, 'SubStaff')}>
                        <span>รายชื่อข้อมูลพนักงาน</span>
                      </NavLink>
                    </li>
									</ul>
								</li>

                <li className={(this.state.menu_point_log === 1 ? (this.state.active === 'Point' ? activeStyle: '' ) : 'menu-none')}>
									<a className="has-ul" onClick={this.handleActive.bind(this, 'Point')}>
                    <img src={process.env.PUBLIC_URL+'/images/icon_afteryou/point_log.png'} className="img-icon" />
                    <span>จัดการคะแนน</span>
                  </a>
									<ul className="hidden-ul" style={this.state.subactive === 'Earn' || this.state.subactive === 'Burn' || this.state.subactive === 'ManagePoint' ? displaySubItem : hideSubItem}>
                    {/* <li className={this.state.subactive === 'ManagePoint' ? activeStyle: ''}>
                        <NavLink to={urlAU + "/backoffice/point/manage"} className={this.state.subactive === 'EaManagePointrn' ? 'navbar-item': 'navbar-item'} onClick={this.handleActiveSubmenu.bind(this, 'ManagePoint')}>
                          <span>แก้ไขข้อมูลการได้รับคะแนน</span>
                        </NavLink>
                    </li> */}
                    <li className={(this.state.menu_point_earn === 1 ? (this.state.subactive === 'Earn' ? activeStyle: '') : 'menu-none')}>
                        <NavLink to={urlAU + "/backoffice/point/earn"} className={this.state.subactive === 'Earn' ? 'navbar-item': 'navbar-item'} onClick={this.handleActiveSubmenu.bind(this, 'Earn')}>
                          <span>ประวัติการรับคะแนน</span>
                        </NavLink>
                    </li>

                    <li className={(this.state.menu_point_burn === 1 ? (this.state.subactive === 'Burn' ? activeStyle: '') : 'menu-none')}>
                        <NavLink to={urlAU + "/backoffice/point/burn"} className={this.state.subactive === 'Burn' ? 'navbar-item': 'navbar-item'} onClick={this.handleActiveSubmenu.bind(this, 'Burn')}>
                          <span>ประวัติการแลกคะแนน</span>
                        </NavLink>
                    </li>

									</ul>
								</li>

                <li className={(this.state.menu_activity === 1 ? (this.state.active === 'Activity' ? activeStyle: '' ) : 'menu-none')}>
                  <NavLink to={urlAU + "/backoffice/activity/all"} className={this.state.active === 'Activity' ? 'navbar-item': 'navbar-item'} onClick={this.handleActive.bind(this, 'Activity')}>
                    <img src={process.env.PUBLIC_URL+'/images/icon_afteryou/news.png'} className="img-icon" />
                    <span>กิจกรรมพิเศษ</span>
                  </NavLink>
                </li>
                
                <li className={(this.state.menu_ewallet === 1 ? (this.state.active === 'Ewallet' ? activeStyle: '' ) : 'menu-none')}>
									<a className="has-ul" onClick={this.handleActive.bind(this, 'Ewallet')}>
                    <img src={process.env.PUBLIC_URL+'/images/icon_afteryou/ewallet.png'} className="img-icon" />
                    <span>E-Wallet</span>
                  </a>
									<ul className="hidden-ul" style={this.state.subactive === 'E-Account' || this.state.subactive === 'E-Transaction' || this.state.subactive === 'E-Topup' ? displaySubItem : hideSubItem}>
                    <li className={(this.state.menu_ewallet_account === 1 ? (this.state.subactive === 'E-Account' ? activeStyle: '') : 'menu-none')}>
                      <NavLink to={urlAU + "/backoffice/ewallet/account/overview"} className={this.state.subactive === 'E-Account' ? 'navbar-item': 'navbar-item'} onClick={this.handleActiveSubmenu.bind(this, 'E-Account')}>
                        <span>บัญชี</span>
                      </NavLink>
                    </li>
                    {/* <li className={this.state.subactive === 'E-Transaction' ? activeStyle: ''}>
                      <NavLink to={urlAU + "/backoffice/ewallet/transaction/recent"} className={this.state.subactive === 'E-Transaction' ? 'navbar-item': 'navbar-item'} onClick={this.handleActiveSubmenu.bind(this, 'E-Transaction')}>
                        <span>Transaction</span>
                      </NavLink>
                    </li> */}
                    <li className={(this.state.menu_ewallet_transaction === 1 ? (this.state.subactive === 'E-Topup' ? activeStyle: '') : 'menu-none')}>
                      <NavLink to={urlAU + "/backoffice/ewallet/transaction/list"} className={this.state.subactive === 'E-Transaction' ? 'navbar-item': 'navbar-item'} onClick={this.handleActiveSubmenu.bind(this, 'E-Transaction')}>
                        <span>Transaction</span>
                      </NavLink>
                    </li>
                    <li className={(this.state.menu_ewallet_report === 1 ? (this.state.subactive === 'E-Report' ? activeStyle: '') : 'menu-none')}>
                      <NavLink to={urlAU + "/backoffice/ewallet/report/daily/settlement"} className={this.state.subactive === 'E-Report' ? 'navbar-item': 'navbar-item'} onClick={this.handleActiveSubmenu.bind(this, 'E-Report')}>
                        <span>Report</span>
                      </NavLink>
                    </li>
									</ul>
								</li>

                <li className={(this.state.menu_admin === 1 ? (this.state.active === 'Admin' ? activeStyle: '' ) : 'menu-none')}>
									<a className="has-ul" onClick={this.handleActive.bind(this, 'Admin')}>
                    <img src={process.env.PUBLIC_URL+'/images/icon_afteryou/admin.png'} className="img-icon" />
                    <span>ผู้ดูแลระบบ</span>
                  </a>
									<ul className="hidden-ul" style={this.state.subactive === 'Admin' || this.state.subactive === 'Level' ? displaySubItem : hideSubItem}>
                    <li className={(this.state.menu_admin_list === 1 ? (this.state.subactive === 'Admin' ? activeStyle: '') : 'menu-none')}>
                      <NavLink to={urlAU + "/backoffice/admin"} className={this.state.subactive === 'Admin' ? 'navbar-item': 'navbar-item'} onClick={this.handleActiveSubmenu.bind(this, 'Admin')}>
                        <span>ผู้ดูแลระบบ</span>
                      </NavLink>
                    </li>
                    <li className={(this.state.menu_permission === 1 ? (this.state.subactive === 'Level' ? activeStyle: '') : 'menu-none')}>
                      <NavLink to={urlAU + "/backoffice/level"} className={this.state.subactive === 'Level' ? 'navbar-item': 'navbar-item'} onClick={this.handleActiveSubmenu.bind(this, 'Level')}>
                        <span>สิทธิ์ผู้ดูแลระบบ</span>
                      </NavLink>
                    </li>

									</ul>
								</li>

                <li className={(this.state.menu_problem === 1 ? (this.state.active === 'Problem' ? activeStyle: '' ) : 'menu-none')}>
									<a className="has-ul" onClick={this.handleActive.bind(this, 'Problem')}>
                    <img src={process.env.PUBLIC_URL+'/images/icon_afteryou/problem.png'} className="img-icon" />
                    <span>รายการแจ้งปัญหาการใช้งานระบบ</span>
                  </a>
									<ul className="hidden-ul" style={this.state.subactive === 'P-Title' || this.state.subactive === 'P-List' ? displaySubItem : hideSubItem}>
                    <li className={(this.state.menu_problem_title === 1 ? (this.state.subactive === 'P-Title' ? activeStyle: '') : 'menu-none')}>
                      <NavLink to={urlAU + "/backoffice/ewallet/problem/title"} className={this.state.subactive === 'P-Title' ? 'navbar-item': 'navbar-item'} onClick={this.handleActiveSubmenu.bind(this, 'P-Title')}>
                        <span>รายการหัวข้อแจ้งปัญหาการใช้งาน</span>
                      </NavLink>
                    </li>

                    <li className={(this.state.menu_problem_list === 1 ? (this.state.subactive === 'P-List' ? activeStyle: '') : 'menu-none')}>
                      <NavLink to={urlAU + "/backoffice/ewallet/problem"} className={this.state.subactive === 'P-List' ? 'navbar-item': 'navbar-item'} onClick={this.handleActiveSubmenu.bind(this, 'P-List')}>
                        <span>รายการแจ้งปัญหาการใช้งาน</span>
                      </NavLink>
                    </li>

									</ul>
								</li>

              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
