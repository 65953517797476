import { Base_API } from "../_constants/matcher";
import axios from "axios";

export const BackofficeAction = {
  getExcelMemberList,
  getExcelBranchList,
  getExcelStaffList,
  getExcelRewardRedeemHistory,
  getExcelRewardRedeemHistoryByFilter
};

function getExcelMemberList() {
  return axios.get(`${Base_API.backoffice}/api/export/member/listMember`, "").then(res => {
    return res.data;
  })
  .catch(err => {
    console.log(err);
  })
}
function getExcelBranchList() {
  return axios.get(`${Base_API.backoffice}/api/export/branch/listBranch`, "").then(res => {
    return res.data;
  })
  .catch(err => {
    console.log(err);
  })
}
function getExcelStaffList() {
  return axios.get(`${Base_API.backoffice}/api/export/staff/listStaff`, "").then(res => {
    return res.data;
  })
  .catch(err => {
    console.log(err);
  })
}
function getExcelRewardRedeemHistory() {
  return axios.get(`${Base_API.backoffice}/api/export/reward/listRedeemReward`, "").then(res => {
    return res.data;
  })
  .catch(err => {
    console.log(err);
  })
}
function getExcelRewardRedeemHistoryByFilter(filter) {
  return axios.get(`${Base_API.backoffice}/api/export/reward/listRedeemRewardByFilter/${filter}`, "").then(res => {
    return res.data;
  })
  .catch(err => {
    console.log(err);
  })
}
