import React from "react";
import { productAction} from "../../../../_actions";
import SweetAlert from "react-bootstrap-sweetalert";
import "../product.css";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import moment from "moment-timezone";

const animatedComponents = makeAnimated();

let modalAlert;

export class Product_Online_Edit extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      modal: null,
      modalOption: {},
      fields: {},
      errors: {},
      isStatus: 0,
      isRecommend: false,
      status: 0,
      cateName:[],
      attrOption: [],
      attrData: [],
      attrSelected: [],
      attrInit: [],
      productCode: "",
      status_type_ems: 0
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onConfirmCreate = this.onConfirmCreate.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.handleSelectAttribute = this.handleSelectAttribute.bind(this);
  }

  async componentDidMount() {

    const {
      match: { params }
    } = this.props;
    this.getProductDataByID(params.pr_online_id);

    await this.getCategoryProductOnline();
    await this.getProductAttributeList();
    await this.getProductAttributeUsed();
  }

  getCategoryProductOnline() {
    return new Promise(async resolve => {
      productAction.getCategoryProductOnline()
      .then(res => {
          this.setState({
              cateName: res.data
          })
          resolve();
      })
    })
  }
  getProductAttributeList() {
    return new Promise(async resolve => {
      productAction.getProductAttributeList()
      .then(res => {
        if(res.isSuccess === true && res.data.length > 0)
        {
          let data = res.data;
          let option = [];
          data.forEach(item => {
            option.push({
              label: item.cate_name_th,
              value: item.pratr_cat_id
            })
          })
    
          this.setState({
            attrData: res.data,
            attrOption: option
          })
          resolve();
        }
      })
    })
  }
  getProductAttributeUsed()
  {
    return new Promise(async resolve => {
      let pr_online_code = this.state.fields.pr_online_code;

      productAction.getProductAttributeUsedByPrCode(pr_online_code)
      .then(res => {
        if(res.isSuccess === true)
        {
          if(res.data.length > 0)
          {
            let data = res.data;
            let option = [];
            let attrSelectedInit = [];
            data.forEach(item => {
              option.push({
                label: item.cate_name_th,
                value: item.pratr_cat_id
              })
              attrSelectedInit.push({
                pratr_cat_id: item.pratr_cat_id,
                pr_online_code: pr_online_code
              })
            })
            this.setState({
              attrInit: option,
              attrSelected: attrSelectedInit,
            })
          }
          this.setState({
            productCode: pr_online_code
          })
          resolve();
        }
      })
      .catch(err => {
        console.log(err);
        resolve();
      })

    })
  }

  getProductDataByID(pr_online_id) {

    productAction.getProductOnlineById(pr_online_id).then(e => {
      if (e.isSuccess === true) {
        this.setState({
          fields: {
            ...this.state.fields,
            "pr_online_id": pr_online_id,
            "pr_online_code": e.data.pr_online_code,
            "pr_online_name": e.data.pr_online_name,
            "pr_online_name_en": e.data.pr_online_name_en,
            "pr_online_description": e.data.pr_online_description,
            "pr_online_description_en": e.data.pr_online_description_en,
            "pr_online_image": e.data.pr_online_image,
            "pr_online_height": e.data.pr_online_height,
            "pr_online_width": e.data.pr_online_width,
            "pr_online_depth": e.data.pr_online_depth,
            "pr_online_weight": e.data.pr_online_weight,
            "pr_online_price": e.data.pr_online_price,
            "pr_online_discount_price": e.data.pr_online_discount_price,
            "pr_online_category_id": e.data.pr_online_category_id,
            "pr_online_category_name": e.data.pr_online_category_name,
            "pr_online_sort": e.data.pr_online_sort,
            "pr_online_give_point": e.data.pr_online_give_point,
            "pr_online_type_ems": e.data.pr_online_type_ems,
            "pr_online_type_gift": e.data.pr_online_type_gift,
            "pr_online_type_delivery": e.data.pr_online_type_delivery,
            "pr_online_type_snackbox": e.data.pr_online_type_snackbox,
            "pr_online_shipping_type_id": e.data.pr_online_shipping_type_id,
            "pr_online_typebadge": e.data.pr_online_typebadge,
            "pr_online_keywords": e.data.pr_online_keywords,
            "pr_online_dayreceive": e.data.pr_online_dayreceive,
            "shipping_name": e.data.shipping_name,
            "is_recommend" : e.data.is_recommend,
            "is_active": e.data.is_active,
            "is_delete": e.data.is_delete,
            "pr_online_image_show": e.data.pr_online_image,
          }
        });

        this.setState({isStatus: String(this.state.fields["is_active"]) == 1});
        this.setState({status: this.state.fields["is_active"]});

        this.setState({is_status_give_point: String(this.state.fields["pr_online_give_point"]) == 1});
        this.setState({status_give_point: this.state.fields["pr_online_give_point"]});

        this.setState({is_status_type_ems: String(this.state.fields["pr_online_type_ems"]) == 1});
        this.setState({status_type_ems: this.state.fields["pr_online_type_ems"]});

        this.setState({is_status_type_delivery: String(this.state.fields["pr_online_type_delivery"]) == 1});
        this.setState({status_type_delivery: this.state.fields["pr_online_type_delivery"]});

        this.setState({is_status_type_gift: String(this.state.fields["pr_online_type_gift"]) == 1});
        this.setState({status_type_gift: this.state.fields["pr_online_type_gift"]});

        this.setState({is_status_type_snackbox: String(this.state.fields["pr_online_type_snackbox"]) == 1});
        this.setState({status_type_snackbox: this.state.fields["pr_online_type_snackbox"]});

        this.setState({isRecommend: this.state.fields.is_recommend === 1 ? true : false});

        if(this.state.fields["pr_online_type_ems"] == 1){
          this.setState({ disabled_shipping_type: false })
        }else{
          this.setState({ disabled_shipping_type: true })
        }
        
        this.setState({defaultCateValue:
          {
            value: this.state.fields['pr_online_category_id'],
            label: this.state.fields['pr_online_category_name']
          }
        });

        this.setState({defaultShippingValue:
          {
            value: this.state.fields['shipping_id'],
            label: this.state.fields['shipping_name']
          }
        });

        this.setState({defaultTypebadgeValue:
          {
            value: this.state.fields['pr_online_typebadge'],
            label: this.state.fields['pr_online_typebadge']
          }
        });

      } else {
        this.onModalError("Error", "ไม่พบข้อมูลดังกล่าว");
      }
    });
  }

  onModalSuccess(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        success
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onConfirm}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalWarning(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        warning
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalError(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        error
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={this.onCancel}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  onModalQuestion(head, body) {
    modalAlert = () => (
      <SweetAlert
        style={{ display: "block" }}
        info
        showCancel
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={() => this.onConfirmCreate()}
        onCancel={() => this.onCancel()}
      >
        {body}
      </SweetAlert>
    );

    this.setState({ show: true, modal: modalAlert() });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ modalOption: null });

    if (this.validateForm()) {
      this.onModalQuestion("Confirm", "คุณต้องการแก้ไขข้อมูล ?");
    }
  }

  onConfirm() {
    this.setState({ modal: null });
    window.location.href = "/backoffice/order-online/product";
  }

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    let { status_type_ems } = this.state;

    if (!fields["pr_online_code"]) {
      formIsValid = false;
      errors["pr_online_code"] = "*กรุณากรอกรหัสสินค้า";
    }

    if (!fields["pr_online_name"]) {
      formIsValid = false;
      errors["pr_online_name"] = "*กรุณากรอกชื่อสินค้า (TH)";
    }

    if (!fields["pr_online_name_en"]) {
      formIsValid = false;
      errors["pr_online_name_en"] = "*กรุณากรอกชื่อสินค้า (EN)";
    }

    if (!fields["pr_online_image"]) {
      formIsValid = false;
      errors["pr_online_image"] = "*กรุณาแนบไฟล์รูปภาพ";
    }

    if (fields["pr_online_height"] === "") {
      formIsValid = false;
      errors["pr_online_height"] = "*กรุณากรอกความสูงของบรรจุภัณฑ์ (ซม.)";
    }

    if (fields["pr_online_width"] === "") {
      formIsValid = false;
      errors["pr_online_width"] = "*กรุณากรอกความกว้างของบรรจุภัณฑ์ (ซม.)";
    }

    if (fields["pr_online_depth"] === "") {
      formIsValid = false;
      errors["pr_online_depth"] = "*กรุณากรอกความลึกของบรรจุภัณฑ์ (ซม.)";
    }

    if (fields["pr_online_weight"] === "") {
      formIsValid = false;
      errors["pr_online_weight"] = "*กรุณากรอกน้ำหนักของสินค้า (กรัม)";
    }

    if (!fields["pr_online_price"]) {
      formIsValid = false;
      errors["pr_online_price"] = "*กรุณากรอกราคาสินค้า";
    }

    if (!fields["pr_online_category_id"]) {
      formIsValid = false;
      errors["pr_online_category_id"] = "*กรุณากรอกหมวดหมู่สินค้า";
    }

    if (!fields["pr_online_sort"]) {
      formIsValid = false;
      errors["pr_online_sort"] = "*กรุณากรอกลำดับสินค้า";
    }

    if(status_type_ems === 1)
    {
      if (!fields["pr_online_shipping_type_id"]) {
        formIsValid = false;
        errors["pr_online_shipping_type_id"] = "*กรุณาระบุประเภทการจัดส่งสินค้า";
      }
    }

    // if (!fields["pr_online_point"]) {
    //   formIsValid = false;
    //   errors["pr_online_point"] = "*กรุณากรอกคะแนนสินค้า";
    // }

    // if (!fields["pr_online_description"]) {
    //   formIsValid = false;
    //   errors["pr_online_description"] = "*กรุณากรอกรายละเอียดสินค้า (TH)";
    // }

    // if (!fields["pr_online_description_en"]) {
    //   formIsValid = false;
    //   errors["pr_online_description_en"] = "*กรุณากรอกรายละเอียดสินค้า (EN)";
    // }

    // if (!fields["discountProduct"]) {
    //   formIsValid = false;
    //   errors["discountProduct"] = "*กรุณากรอกส่วนลดสินค้า";
    // }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  async onConfirmCreate(ev) {
    this.setState({ modal: null });
    
    let dataAttr = await this.reNewDataAttr();

    var formData = new FormData();
    formData.append("pr_online_code", this.state.fields["pr_online_code"]);
    formData.append("pr_online_name", this.state.fields["pr_online_name"]);
    formData.append("pr_online_name_en", this.state.fields["pr_online_name_en"]);
    formData.append("pr_online_description", this.state.fields["pr_online_description"]);
    formData.append("pr_online_description_en", this.state.fields["pr_online_description_en"]);
    formData.append("pr_online_height", this.state.fields["pr_online_height"]);
    formData.append("pr_online_width", this.state.fields["pr_online_width"]);
    formData.append("pr_online_depth", this.state.fields["pr_online_depth"]);
    formData.append("pr_online_weight", this.state.fields["pr_online_weight"]);
    formData.append("pr_online_image", this.state.fields["pr_online_image"]);
    formData.append("pr_online_price", this.state.fields["pr_online_price"]);
    formData.append("pr_online_discount_price", this.state.fields["pr_online_discount_price"]);
    formData.append("pr_online_category_id", this.state.fields["pr_online_category_id"]);
    formData.append("pr_online_sort", this.state.fields["pr_online_sort"]);
    formData.append("pr_online_give_point", this.state.status_give_point);
    formData.append("pr_online_type_gift", this.state.status_type_gift);
    formData.append("pr_online_type_snackbox", this.state.status_type_snackbox);
    formData.append("pr_online_type_ems", this.state.status_type_ems);
    formData.append("pr_online_type_delivery", this.state.status_type_delivery);
    formData.append("pr_online_shipping_type_id", this.state.status_type_ems === 1 ? this.state.fields["pr_online_shipping_type_id"] : '');
    formData.append("pr_online_typebadge", this.state.fields["pr_online_typebadge"]);
    formData.append("pr_online_keywords", this.state.fields["pr_online_keywords"]);
    formData.append("pr_online_dayreceive", this.state.fields["pr_online_dayreceive"]);
    formData.append("is_active", this.state.status);
    formData.append("is_recommend", this.state.fields.is_recommend);
    formData.append("attribute_set", JSON.stringify(dataAttr));

    // ( V1 )
    // productAction.updateProductOnline(formData, this.state.fields["pr_online_id"])
    // .then(e => {
    //   console.log(e)
    //   if (e.isSuccess === true) {
    //     this.onModalSuccess("Success", e.message);
    //   } else {
    //     this.onModalError("Error", e.message);
    //   }
    // });

    // ( V2 )
    productAction.updateProductOnlineV2(formData, this.state.fields["pr_online_id"])
    .then(e => {
      if (e.isSuccess === true) {
        this.onModalSuccess("Success", e.message);
      } else {
        this.onModalError("Error", e.message);
      }
    });
  }

  reNewDataAttr() {
    return new Promise(async resolve => {
      let {attrSelected, fields, productCode} = this.state;  

      if(attrSelected.length > 0)
      {
        attrSelected.forEach(item => {
          item.created_at = moment().format("Y-MM-DD HH:mm:ss");
          item.pr_online_code = productCode;
        })
        resolve(attrSelected);
      }else
      {
        resolve([]);
      }
    })
  }

  onCancel(ev) {
    this.setState({ modal: null });
  }

  handleChange = (e) => {
    let { fields, errors } = this.state;
    fields[e.target.name] = e.target.value;
    this.state.errors[e.target.name] = null;
    this.setState({ fields, errors });
  };

  handleSelectChange = (e, action) => {
    let { fields, errors } = this.state;
    if(action.name == "pr_online_category_id"){
      this.setState({
        defaultCateValue:
        {
          label: e.label,
          value: e.value
        }
      })
    }else if(action.name == "pr_online_shipping_type_id"){
      this.setState({
        defaultShippingValue:
        {
          label: e.label,
          value: e.value
        }
      })
    }else if(action.name == "pr_online_typebadge"){
      this.setState({
        defaultTypebadgeValue:
        {
          label: e.label,
          value: e.value
        }
      })
    }
    fields[action.name] = e.value;
    this.state.errors[action.name] = null;
    this.setState({ fields, errors });
  };

  handleSelectAttribute = (list, action) => {
    let actionName = action.name;

    if(actionName === 'product_attribute')
    {
      let newAttrSelected = [];

      this.setState({
        attrInit: list
      })

      if(!!list)
      {
        for(var i in list)
        {
          let index = Number(i);
          let itemAttr = {
            pratr_cat_id: list[index].value
          }
          newAttrSelected.push(itemAttr);
  
          if(index === list.length-1)
          {
            this.setState({
              attrSelected: newAttrSelected
            })
          }
        }
      }else
      {
        this.setState({
          attrSelected: []
        })
      }
    }
  };

  handleInputChange = e => {

  const target = e.target;
  const name = target.name;

  if(name == "isStatus"){
    this.setState({ isStatus: !this.state.isStatus })
    if(this.state.isStatus == true){
      this.setState({ status: 0 })
    }else{
      this.setState({ status: 1 })
    }
  }
  if(name === "isRecommend")
  {
    if(this.state.isRecommend === true)
    {
      this.setState({fields: {...this.state.fields, is_recommend: 0 }})
    }else
    {
      this.setState({fields: {...this.state.fields, is_recommend: 1 }})
    }
    this.setState({ isRecommend: !this.state.isRecommend })
  }
  if(name == "is_status_give_point"){
    this.setState({ is_status_give_point: !this.state.is_status_give_point })
    if(this.state.is_status_give_point == true){
      this.setState({ status_give_point: 0 })
    }else{
      this.setState({ status_give_point: 1 })
    }
  }
  if(name == "is_status_type_ems"){
    this.setState({ is_status_type_ems: !this.state.is_status_type_ems })

    if(this.state.is_status_type_ems == true){
      this.setState({ 
        status_type_ems: 0,
        disabled_shipping_type: true
      })
    }else{
      this.setState({ 
        status_type_ems: 1 ,
        disabled_shipping_type: false
      })
    }
  }
  if(name == "is_status_type_delivery"){
    this.setState({ is_status_type_delivery: !this.state.is_status_type_delivery })
    if(this.state.is_status_type_delivery == true){
      this.setState({ status_type_delivery: 0 })
    }else{
      this.setState({ status_type_delivery: 1 })
    }
  }
  if(name == "is_status_type_gift"){
    this.setState({ is_status_type_gift: !this.state.is_status_type_gift })
    if(this.state.is_status_type_gift == true){
      this.setState({ status_type_gift: 0 })
    }else{
      this.setState({ status_type_gift: 1 })
    }
  }
  if(name == "is_status_type_snackbox"){
    this.setState({ is_status_type_snackbox: !this.state.is_status_type_snackbox })
    if(this.state.is_status_type_snackbox == true){
      this.setState({ status_type_snackbox: 0 })
    }else{
      this.setState({ status_type_snackbox: 1 })
    }
  }
}

  onChangeUploadHandler = e => {
    let { fields, errors } = this.state;
    fields[e.target.name] = e.target.files[0];
    this.state.errors[e.target.name] = null;
    this.setState({
      fields,
      errors
    });
  };

  render() {
    let {attrOption, attrInit} = this.state;
    let options_category_id = this.state.cateName.map(function (cateName) {
      return { value: cateName.pr_online_category_id, label: cateName.pr_online_category_name };
    })

    let optionsShipping = [
      { value: "", label: "กรุณาเลือกประเภทสินค้า" },
      { value: '1', label: "สินค้าประเภทของแห้ง" },
      { value: '2', label: "สินค้าประเภทของแช่เย็น" },
      { value: '3', label: "สินค้าประเภทของแช่แข็ง" }
    ]

    let optionsTypebadge = [
      { value: "normal", label: "normal" },
      { value: "recommend", label: "recommend" },
      { value: "hot", label: "hot" },
      { value: "promotion", label: "promotion" },
      { value: "new", label: "new" }
    ]

    return (
      <div>
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">แก้ไขสินค้าสั่งซื้อออนไลน์</h4>
          </div>
          <br />
          <div className="panel-body">
            <div className="text-center mb-5">
              <img
                className="preview-img"
                src={this.state.fields["pr_online_image_show"] || ""}
                alt={this.state.fields["pr_online_name"] || ""}
              />
              </div>

            <form
              className="form-horizontal"
              onSubmit={e => this.handleSubmit(e)}
            >

              <fieldset className="content-group">

              <div className="form-group">
                  <label className="control-label col-lg-2">รูปภาพสินค้า
                    <div className="text-danger"> (1280 x 1280 px)</div>
                  </label>
                  <div className="col-lg-10">
                    <input
                      type="file"
                      className="file-input"
                      name="pr_online_image"
                      onChange={this.onChangeUploadHandler}
                    />
                    <div className="errorMsg">{this.state.errors.pr_online_image}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">รหัสสินค้า</label>
                  <div className="col-lg-10">
                  <input
                      name="pr_online_code"
                      type="text"
                      className="form-control"
                      placeholder="กรอกรหัสสินค้า"
                      value={this.state.fields["pr_online_code"] || ""}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.pr_online_code}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">ชื่อสินค้า (TH)</label>
                  <div className="col-lg-4">
                  <input
                      name="pr_online_name"
                      type="text"
                      className="form-control"
                      placeholder="กรอกชื่อสินค้า (TH)"
                      value={this.state.fields["pr_online_name"] || ""}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.pr_online_name}</div>
                  </div>

                  <label className="control-label col-lg-2">ชื่อสินค้า (EN)</label>
                  <div className="col-lg-4">
                  <input
                      name="pr_online_name_en"
                      type="text"
                      className="form-control"
                      placeholder="กรอกชื่อสินค้า (EN)"
                      value={this.state.fields["pr_online_name_en"] || ""}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.pr_online_name_en}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">หมวดหมู่สินค้า</label>
                  <div className="col-lg-4">
                    <Select
                      name="pr_online_category_id"
                      placeholder="เลือกหมวดหมู่สินค้า"
                      onChange={this.handleSelectChange}
                      options={options_category_id}
                      value={this.state.defaultCateValue ||""}
                      clearable={true}
                    />
                    <div className="errorMsg">{this.state.errors.pr_online_category_id}</div>
                  </div>

                  <label className="control-label col-lg-2">ป้ายกำกับสินค้า</label>
                  <div className="col-lg-4">
                    <Select
                      name="pr_online_typebadge"
                      placeholder="เลือกป้ายกำกับสินค้า"
                      onChange={this.handleSelectChange}
                      options={optionsTypebadge}
                      value={this.state.defaultTypebadgeValue ||""}
                    />
                    <div className="errorMsg">{this.state.errors.pr_online_typebadge}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">สถานะสินค้า (Recommend)</label>
                  <div className="col-lg-4">
                  <label className="switch">
                    <input name="isRecommend" type="checkbox" onChange={this.handleInputChange} checked={this.state.isRecommend}/>
                    <div className="slider"></div>
                  </label>
                  </div>

                  <div></div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">ความสูงของบรรจุภัณฑ์ (ซม.)</label>
                  <div className="col-lg-4">
                  <input
                      name="pr_online_height"
                      type="text"
                      className="form-control"
                      placeholder="ความสูงของบรรจุภัณฑ์ (ซม.)"
                      value={this.state.fields["pr_online_height"]}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.pr_online_height}</div>
                  </div>

                  <label className="control-label col-lg-2">ความกว้างของบรรจุภัณฑ์ (ซม.)</label>
                  <div className="col-lg-4">
                  <input
                      name="pr_online_width"
                      type="text"
                      className="form-control"
                      placeholder="ความกว้างของบรรจุภัณฑ์ (ซม.)"
                      value={this.state.fields["pr_online_width"]}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.pr_online_width}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">ความลึกของบรรจุภัณฑ์ (ซม.)</label>
                  <div className="col-lg-4">
                  <input
                      name="pr_online_depth"
                      type="text"
                      className="form-control"
                      placeholder="ความลึกของบรรจุภัณฑ์ (ซม.)"
                      value={this.state.fields["pr_online_depth"]}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.pr_online_depth}</div>
                  </div>

                  <label className="control-label col-lg-2">น้ำหนักของสินค้า (กรัม)</label>
                  <div className="col-lg-4">
                  <input
                      name="pr_online_weight"
                      type="text"
                      className="form-control"
                      placeholder="น้ำหนักของบรรจุภัณฑ์ (กรัม)"
                      value={this.state.fields["pr_online_weight"]}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.pr_online_weight}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">รายละเอียด (TH)</label>
                  <div className="col-lg-10">
                  <textarea className="form-control"
                      name="pr_online_description"
                      placeholder="กรอกรายละเอียดสินค้า (TH)"
                      value={this.state.fields["pr_online_description"] || ""}
                      onChange={this.handleChange}
                      rows="3">
                  </textarea>
                    <div className="errorMsg">{this.state.errors.pr_online_description}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">รายละเอียด (EN)</label>
                  <div className="col-lg-10">
                  <textarea className="form-control"
                      name="pr_online_description_en"
                      placeholder="กรอกรายละเอียดสินค้า (EN)"
                      value={this.state.fields["pr_online_description_en"] || ""}
                      onChange={this.handleChange}
                      rows="3">
                  </textarea>
                    <div className="errorMsg">{this.state.errors.pr_online_description_en}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">ราคาเริ่มต้น (฿)</label>
                  <div className="col-lg-4">
                  <input
                      name="pr_online_price"
                      type="number"
                      className="form-control"
                      placeholder="กรอกราคาสินค้า"
                      value={this.state.fields["pr_online_price"] || ""}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.pr_online_price}</div>
                  </div>

                  <label className="control-label col-lg-2">ลำดับที่แสดง</label>
                  <div className="col-lg-4">
                    <input
                      name="pr_online_sort"
                      type="number"
                      min="0"
                      className="form-control"
                      placeholder="กรอกลำดับที่แสดง"
                      value={this.state.fields["pr_online_sort"]}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.pr_online_sort}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">สถานะแสดงสินค้าตอนค้นหา Delivery & Pickup & Snackbox & Eatin</label>
                  <div className="col-lg-4">
                  <label className="switch">
                    <input name="is_status_type_delivery" type="checkbox" onChange={this.handleInputChange} checked={this.state.is_status_type_delivery}/>
                    <div className="slider"></div>
                  </label>
                  </div>

                  <label className="control-label col-lg-2">สถานะแสดงสินค้าตอนค้นหา EMS</label>
                  <div className="col-lg-4">
                  <label className="switch">
                    <input name="is_status_type_ems" type="checkbox" onChange={this.handleInputChange} checked={this.state.is_status_type_ems}/>
                    <div className="slider"></div>
                  </label>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">สถานะแสดงสินค้าตอนค้นหา Gift</label>
                  <div className="col-lg-4">
                  <label className="switch">
                    <input name="is_status_type_gift" type="checkbox" onChange={this.handleInputChange} checked={this.state.is_status_type_gift}/>
                    <div className="slider"></div>
                  </label>

                  </div>
                  <label className="control-label col-lg-2">สถานะแสดงสินค้าตอนค้นหา Snack box</label>
                  <div className="col-lg-4">
                  <label className="switch">
                    <input name="is_status_type_snackbox" type="checkbox" onChange={this.handleInputChange} checked={this.state.is_status_type_snackbox}/>
                    <div className="slider"></div>
                  </label>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">ประเภทการจัดส่งสินค้า <br />(เฉพาะสินค้า EMS)</label>
                  <div className="col-lg-4">
                    <Select
                      name="pr_online_shipping_type_id"
                      placeholder="เลือกประเภทการจัดส่งสินค้า"
                      onChange={this.handleSelectChange}
                      options={optionsShipping}
                      isDisabled={this.state.disabled_shipping_type}
                      value={this.state.defaultShippingValue}
                    />
                    <div className="errorMsg">{this.state.errors.pr_online_shipping_type_id}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">จำนวนวันขั้นต่ำของวันที่รับสินค้า <br /> (เฉพาะสินค้า Snackbox)</label>
                  <div className="col-lg-4">
                    <input
                      name="pr_online_dayreceive"
                      type="number"
                      className="form-control"
                      placeholder="กรอกจำนวนวันที่รับสินค้าขั้นต่ำ"
                      onChange={this.handleChange}
                      value={this.state.fields["pr_online_dayreceive"]}
                    />
                    <div className="errorMsg">{this.state.errors.pr_online_dayreceive}</div>
                  </div>
                </div>
                
                {/* <div className="form-group">
                  <label className="control-label col-lg-2">คะแนนสินค้า (P)</label>
                  <div className="col-lg-4">
                  <input
                      name="pr_online_point"
                      type="number"
                      className="form-control"
                      placeholder="กรอกคะแนนสินค้า"
                      value={this.state.fields["pr_online_point"] || ""}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.pr_online_point}</div>
                  </div>
                </div> */}

                {/* <div className="form-group">
                  <label className="control-label col-lg-2">ส่วนลด (บาท)</label>
                  <div className="col-lg-4">
                  <input
                      name="discountProduct"
                      type="number"
                      step='0.01'
                      className="form-control"
                      placeholder="กรอกส่วนลดสินค้า"
                      value={this.state.fields["discountProduct"] || "0.00"}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.discountProduct}</div>
                  </div>
                </div> */}

                <div className="form-group">
                  <label className="control-label col-lg-2">keyword สินค้า (มีผลต่อการค้นหา)
                  <div className="text-danger"> (ขั่นคำด้วย "," เช่น ขนม,afteryou)</div></label>
                  <div className="col-lg-10">
                  <input
                      name="pr_online_keywords"
                      type="text"
                      className="form-control"
                      placeholder="keyword สินค้า"
                      value={this.state.fields["pr_online_keywords"]}
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.pr_online_keywords}</div>
                  </div>
                </div>
                
                <div className="form-group">
                  <label className="control-label col-lg-2">คุณลักษะสินค้า <div className="text-danger"> (สามารถพิมพ์ค้นหาได้)</div></label>
                  <div className="col-lg-4">
                    <Select
                      name="product_attribute"
                      placeholder="เลือกคุณลักษณะสินค้า"
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      onChange={this.handleSelectAttribute}
                      isMulti
                      value={attrInit}
                      options={attrOption}
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">สถานะการให้คะแนน</label>
                  <div className="col-lg-5">
                    <label className="switch">
                      <input name="is_status_give_point" type="checkbox" onChange={this.handleInputChange} checked={this.state.is_status_give_point}/>
                      <div className="slider"></div>
                    </label>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-lg-2">สถานะ</label>
                  <div className="col-lg-5">
                    <label className="switch">
                      <input name="isStatus" type="checkbox" onChange={this.handleInputChange} checked={this.state.isStatus}/>
                      <div className="slider"></div>
                    </label>
                  </div>
                </div>

                <div className="form-group">
                  <div className="col-lg-12 text-right">
                    <input
                      type="submit"
                      className="btn btn-success btn-md btn-banner-create"
                      value="อัพเดท"
                    />
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
